import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {observer} from "mobx-react-lite";
import {classNames, useStore} from "../../../../helpers";
import Select from 'react-select';
import {toJS} from "mobx";
import {useTranslation} from "react-i18next";
import {TailwindModal} from "../../../../components";

const Ileader = {
    "name": "",
    "caste": "",
    "phone": "",
    "alliance": "",
    "position": "",
    "comments": ""
}

export const AddInfluentialLeader = observer((props: any) => {

    const {onSave, onCancel, data} = props;
    const rootStore = useStore();
    const {flexiStore} = rootStore;
    const {shared} = data;
    const {caste, party} = shared;
    const cancelButtonRef = useRef(null);
    const [leader, setLeader] = useState(Ileader);
    const isFormEmpty = Object.keys(leader).includes(" ");
    const [t] = useTranslation(["influential_leaders", "beneficiary"])

    useEffect(() => {
        if (flexiStore.influential_leader_edit_mode) {
            setLeader(toJS(flexiStore.active_influential_leader["info"]));
        } else {
            setLeader(Ileader);
        }
    }, [flexiStore.influential_leader_edit_mode, flexiStore.active_influential_leader]);


    return (
        <TailwindModal isOpen={flexiStore.influential_leader_add_modal}>
            <div
                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="p-4">
                    <div className="text-xl font-semibold text-gray-900 pb-4">{t("add_influential_leader")}</div>
                    <form>
                        <label className="block text-sm font-medium text-gray-700 pt-4"
                               htmlFor="name">{t("name", {ns: "beneficiary"})}</label>
                        <input type="text"
                               className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border-solid border p-2"
                               value={leader.name}
                               onChange={event => {
                                   const name = event.target.value;
                                   // @ts-ignore
                                   setLeader({...leader, name: name})
                               }}
                        />
                    </form>

                    <form>
                        <label htmlFor="phone"
                               className="block text-sm font-medium text-gray-700 pt-4">{t("phone_number", {ns: "beneficiary"})}</label>
                        <input type="text"
                               className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"
                               value={leader.phone}
                               maxLength={10}
                               onChange={event => {
                                   const phone = event.target.value;
                                   // @ts-ignore
                                   setLeader({...leader, "phone": phone})
                               }}
                        />
                    </form>
                    <form>
                        <label htmlFor="caste"
                               className="block text-sm font-medium text-gray-700 pt-4">{t("caste", {ns: "beneficiary"})}</label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            value={
                                caste.filter(option =>
                                    option.value === leader.caste)
                            }
                            onChange={e => {
                                try {
                                    const caste = e.value;
                                    setLeader({...leader, caste: caste})
                                } catch (e) {
                                    //
                                }
                            }}
                            isClearable={true}
                            isSearchable={true}
                            name="caste"
                            options={caste}
                        />

                    </form>
                    <form>
                        <label htmlFor="alliance"
                               className="block text-sm font-medium text-gray-700 pt-4">{t("party_alliance")}</label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            value={
                                party.filter(option =>
                                    option.value === leader.alliance)
                            }
                            onChange={e => {
                                try {
                                    const party = e.value;
                                    setLeader({...leader, alliance: party});
                                } catch (e) {
                                    //
                                }
                            }}
                            isClearable={true}
                            isSearchable={true}
                            name="alliance"
                            options={party}
                        />
                    </form>
                    <form>
                        <label htmlFor="position"
                               className="block text-sm font-medium text-gray-700 pt-4">{t("position")}</label>
                        <input type="text"
                               className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"
                               value={leader.position}
                               onChange={event => {
                                   const position = event.target.value
                                   // @ts-ignore
                                   setLeader({...leader, position: position})
                               }}
                        />
                    </form>
                    <form>
                        <label htmlFor="comments"
                               className="block text-sm font-medium text-gray-700 pt-4">{t("comments")}</label>
                        <textarea
                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"
                            rows={3}
                            value={leader.comments}
                            onChange={event => {
                                const txt = event.target.value
                                // @ts-ignore
                                setLeader({...leader, comments: txt})
                            }}
                        >
                                </textarea>
                    </form>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse justify-start">
                    <button
                        type="button"
                        // className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        className={classNames(isFormEmpty ? "pointer-events-none opacity-50" : "",
                            "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500")}

                        onClick={(evt) => {
                            onSave(leader);
                        }}
                        disabled={isFormEmpty}
                    >
                        {flexiStore.influential_leader_edit_mode ? "Update" : "Save"}
                    </button>
                    <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                        onClick={() => {
                            onCancel();
                        }}
                        ref={cancelButtonRef}
                    >
                        {t("cancel", {ns: "beneficiary"})}
                    </button>
                </div>
            </div>
        </TailwindModal>
    )
})