import React from "react"
import {observer} from "mobx-react-lite";
import {isEmpty} from "lodash"
import {DataNotAvailable} from "./mh-2024-fieldview-main-page";

export const MHSwotAnalysis = observer((props: any) => {

    if(isEmpty(props.data)) return <DataNotAvailable />;

    return(
        <>

        </>
    )
})