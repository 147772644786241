import { action, makeAutoObservable, toJS } from "mobx";
import {
  FlexiStoreData,
  FlexiStoreData_Abridged,
  FlexiStoreTemplate,
  FlexiStoreTemplate_Abridged,
} from "./fs/fst";
import { instanceToPlain, plainToInstance } from "class-transformer";
import { safe_get } from "../report-visuals";
import _ from "lodash";

export class FlexiStore {
  rootStore: any;

  fst_query: string;
  fst_results: Array<FlexiStoreTemplate_Abridged>;

  fsd_search_query: string = "";
  fsd_search_key: string = "";
  fsd_search_page_size: number = 100;
  fsd_search_current_page: number = 1;
  fsd_search_results: Array<FlexiStoreData_Abridged>;

  current_fsd: FlexiStoreData;
  current_fst: FlexiStoreTemplate;
  current_fsd_ready: boolean;

  loading_external_resource: boolean;
  external_resource_key: string = "";
  external_resource: any;
  loading: boolean = false;
  ac_number: number = null;

  group_by_booth: any = {};
  swot_by_booth: any = [];
  show_booth_numbers: boolean = false;

  //influential leader related stuff
  influential_leaders: any;
  influential_leader_search_query: string;
  influential_leader_add_modal: boolean = false;
  influential_leader_edit_mode: boolean = false;
  active_influential_leader: any = {};

  timeline_list: any = [];
  Voterlist: any = [];

  active_timeline: any = {};
  timeline_search_query: string;
  timeline_edit_mode: boolean = false;
  slate_data: any = [];

  manage_fsd_permissions_overlay:boolean = false;
  add_users_to_fsd_overlay: boolean = false;

  show_booth_report_persmissions: boolean = false;

  constructor(rootStore: any) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.reset_fst_search();
  }

  @action
  update_fsd_search_page_size(val: number) {
    this.fsd_search_page_size = val;
  }

  @action
  reset_fst_search() {
    this.fst_query = "";
    this.fst_results = new Array<FlexiStoreTemplate_Abridged>();
  }

  @action
  reset_fsd_search() {
    this.fsd_search_query = "";
    this.fsd_search_key = "";
    this.fsd_search_page_size = 100;
    this.fsd_search_current_page = 1;
    this.fsd_search_results = new Array<FlexiStoreData_Abridged>();
  }

  @action
  async get_search_fs_data() {
    const payload = {
      key: this.fsd_search_key.trim(),
      query: this.fsd_search_query.trim(),
      count: this.fsd_search_page_size,
      offset: (this.fsd_search_current_page - 1) * this.fsd_search_page_size,
    };
    const response = await this.rootStore.api.post("fs/fsdsearch", payload);
    // @ts-ignore
    this.fsd_search_results = plainToInstance(
      FlexiStoreData_Abridged,
      response.data
    );
  }

  @action
  async fetch_fsdlist(key, projectid, filters = {}) {
    const payload = {
      key: key,
      query: this.fsd_search_query.trim(),
      offset: (this.fsd_search_current_page - 1) * this.fsd_search_page_size,
      count: this.fsd_search_page_size,
      projectid: projectid,
      filters: filters,
    };
    return await this.rootStore.api.post("fs/fsdsearchprojectscope", payload);
  }

  @action
  async get_fsdata(fsdid) {
    const response = await this.rootStore.api.post("fs/fsdget", {
      fsdid: parseInt(fsdid),
    });
    // @ts-ignore
    this.current_fsd = plainToInstance(FlexiStoreData, response.data);
    // @ts-ignore
    //this.current_fsd.setFST(FlexiStoreData, response.data["fst"])
    this.current_fsd_ready = true;
    return response;
  }

  @action
  set_current_fsd(fsd_data) {
    this.current_fsd = plainToInstance(FlexiStoreData, fsd_data);
  }

  @action
  set_current_fst(fst_data) {
    this.current_fst = plainToInstance(FlexiStoreTemplate, fst_data);
  }

  @action
  get_search_fs_templates = async () => {
    const response = await this.rootStore.api.post("fs/fstsearch", {
      query: this.fst_query,
    });
    // @ts-ignore
    this.fst_results = plainToInstance(
      FlexiStoreTemplate_Abridged,
      response.data
    );

    return response;
  };

  @action
  set_fst_search_query(text) {
    this.fst_query = text;
  }

  @action
  setVoterList(val) {
    this.Voterlist = val;
  }

  @action
  set_fsd_search_key(text) {
    this.fsd_search_key = text;
  }

  @action
  set_fsd_search_query(text) {
    this.fsd_search_query = text;
  }

  @action
  set_fsd_ready_state(ready_state) {
    this.current_fsd_ready = ready_state;
  }

  @action
  get_current_fsd_render_key() {
    try {
      if (this.current_fsd_ready) {
        return this.current_fsd.renderkey;
      } else return "";
    } catch (e) {
      return "";
    }
  }

  @action
  async api_update_current_fsd() {
    const payload = instanceToPlain(this.current_fsd);
    return await this.rootStore.api.post("fs/fsdupdate", payload);
  }

  @action
  update_current_fsd(field_to_update, payload) {
    if (field_to_update == "summary") this.current_fsd.summary = payload;
    if (field_to_update == "projectid") this.current_fsd.projectid = payload;
    if (field_to_update == "r1") this.current_fsd.r1 = payload;
    if (field_to_update == "r2") this.current_fsd.r2 = payload;
    if (field_to_update == "r3") this.current_fsd.r3 = payload;
    if (field_to_update == "r4") this.current_fsd.r4 = payload;
    if (field_to_update == "r5") this.current_fsd.r5 = payload;
    if (field_to_update == "r6") this.current_fsd.r6 = payload;
    if (field_to_update == "r7") this.current_fsd.r7 = payload;
    if (field_to_update == "r8") this.current_fsd.r8 = payload;
    if (field_to_update == "r9") this.current_fsd.r9 = payload;
    if (field_to_update == "r10") this.current_fsd.r10 = payload;
    if (field_to_update == "r11") this.current_fsd.r11 = payload;
    if (field_to_update == "r12") this.current_fsd.r12 = payload;
    // await this.api_update_current_fsd();
  }

  @action
  async clone_fsd(fsd_id) {
    return await this.rootStore.api.post("fs/fsdclone", { fsdid: fsd_id });
  }

  @action
  async delete_fsd(fsd_id) {
    return await this.rootStore.api.post("fs/fsddelete", { fsdid: fsd_id });
  }

  @action
  async get_fsd_permissions(payload) {
    return await this.rootStore.api.post("fs/fsdpermissions", payload);
  }

  @action
  async add_fsd_permissions(add_permissions) {
    return await this.rootStore.api.post(
      "fs/fsdaddpermissions",
      add_permissions
    );
  }

  @action
  async revoke_fsd_permissions(deletePermissions) {
    return await this.rootStore.api.post(
      "fs/fsdrevokepermissions",
      deletePermissions
    );
  }

  @action
  set_loading(state) {
    this.loading = state;
  }

  @action
  set_loading_external_resource(loading_state) {
    this.loading_external_resource = loading_state;
  }

  @action
  async load_s3_json_resource(bucket, path, key = "") {
    this.loading_external_resource = true;
    // @ts-ignore
    const url: string = await this.rootStore.get_s3_url(bucket, path);
    this.external_resource = await this.rootStore.get_json_resource(url);
    this.external_resource_key = key;
    console.log("external_resource", toJS(this.external_resource));
    this.loading_external_resource = false;
    return this.external_resource
  }

  @action
  set_ac_number(num) {
    this.ac_number = num;
  }

  ////////////////////////////////////////// Booth Reports  //////////////////////////////////////////

  @action
  async override_booth_report(updated_booth_json, booth_prefix, booth_no) {
    this.current_fsd.r3 = updated_booth_json;
    let edit_locked = safe_get(this.current_fsd.r1, "config.edit_locked", []);
    console.log("Existing", edit_locked);
    edit_locked.push(`${booth_prefix}${booth_no}`);
    console.log("Updated", edit_locked);
    this.current_fsd.r1["config"]["edit_locked"] = _.uniq(edit_locked);
    await this.api_update_current_fsd();
  }

  @action
  update_group_by_booth(booths) {
    this.group_by_booth = booths;
  }

  @action
  update_swot_by_booth(booths) {
    this.swot_by_booth = booths;
  }

  ////////////////////////////////////////// Influential Leaders  //////////////////////////////////////////

  @action
  set_influential_leader_search_query(query) {
    this.influential_leader_search_query = query;
  }

  @action
  set_add_leader_modal(status) {
    this.influential_leader_add_modal = status;
  }

  @action
  set_active_leader(leader) {
    this.active_influential_leader = leader;
  }

  @action
  set_ileader_edit_mode(mode) {
    this.influential_leader_edit_mode = mode;
  }

  @action
  async get_influential_leaders() {
    this.loading = true;
    const state = this.fsd_search_key.slice(0, 2);
    const payload = {
      query: this.influential_leader_search_query,
      context: "",
      state: state,
      acno: this.ac_number,
      offset: 0,
      count: 100,
    };
    const res = await this.rootStore.api.post("dbh/inflencersearch", payload);
    this.influential_leaders = res.data;
    this.loading = false;
  }

  @action
  async add_influential_leader(payload) {
    const full_payload = {
      acno: this.ac_number,
      state: this.fsd_search_key.slice(0, 2),
      info: payload,
    };
    return await this.rootStore.api.post("dbh/inflencernew", full_payload);
  }

  @action
  async update_influential_leader(payload) {
    const full_payload = {
      id: this.active_influential_leader["id"],
      acno: this.ac_number,
      state: this.fsd_search_key.slice(0, 2),
      info: payload,
    };
    return await this.rootStore.api.post("dbh/inflenceredit", full_payload);
  }

  @action
  async delete_influential_leader(id) {
    return await this.rootStore.api.post("dbh/inflencerdelete", { id });
  }

  ////////////////////////////////////////// Timeline  //////////////////////////////////////////

  @action
  async add_timeline(payload) {
    return await this.rootStore.api.post("dbh/tlnew", {
      ...payload,
      summary: { ...payload.summary, description: this.slate_data },
    });
  }

  @action
  async update_timeline(data) {
    const payload = {
      id: this.active_timeline["id"],
      summary: { ...data.summary, description: this.slate_data },
      acno: data.acno,
      state: data.state,
      attach: data.attach,
      details: data.details,
    };
    return await this.rootStore.api.post("dbh/tlupdate", payload);
  }

  @action
  async getVoterSearch(payload) {
    const response = await this.rootStore.api.post("voters/search", payload);
    this.Voterlist = response?.data?.results;
    this.setVoterList(response?.data?.results);
    console.log(response.data.results, " response.data;");
    return response;
  }

  @action
  async get_timeline(payload) {
    return await this.rootStore.api.post("dbh/tlsearch", payload);
    // const response = await this.rootStore.api.post("dbh/tlsearch", payload);
    // if(response.status === 200){
    //     this.timeline_list = response.data;
    // } else this.timeline_list = [];
  }

  @action
  async delete_timeline(id) {
    return await this.rootStore.api.post("dbh/tldelete", { id });
  }

  @action
  set_slate_data(data) {
    this.slate_data = data;
  }

  @action
  update_timeline_property(property, val) {
    switch (property) {
      case "active_timeline":
        this.active_timeline = val;
        break;
      case "timeline_edit_mode":
        this.timeline_edit_mode = val;
        break;
      case "timeline_search_query":
        this.timeline_search_query = val;
        break;
      case "show_booth_numbers":
        this.show_booth_numbers = val;
        break;
    }
  }

  @action
  update_flexistore_property(property, val) {
    switch (property) {
      case "fsd_search_page_size":
        this.fsd_search_page_size = val;
        break;
      case "fsd_search_current_page":
        this.fsd_search_current_page = val;
        break;
      case "manage_fsd_permissions_overlay":
        this.manage_fsd_permissions_overlay = val;
        break;
      case "add_users_to_fsd_overlay":
        this.add_users_to_fsd_overlay = val;
        break;
    }
  }

  @action
  createfsd = async (payload) => {
    return await this.rootStore.api.post("fs/fsdnew", payload);
  };

  @action
  set_show_booth_numbers(val) {
    this.show_booth_numbers = val;
  }

  @action
  set_show_booth_report_persmissions(val) {
    this.show_booth_report_persmissions = val;
  }
}
