import {observer} from "mobx-react-lite";
import React, {useEffect} from "react"
import {useStore} from "../../helpers";
import {safe_get} from "../../report-visuals/report-utils";
import {toJS} from "mobx";
import {useTranslation} from "react-i18next";
import {CMTable} from "../../components";

function get_total(data, index) {
    try {
        let totalSurveys = 0
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                totalSurveys += data[i][index];
            }
        }
        return totalSurveys
    } catch (e) {
        return 0 
    }
}

const ElectoralDemographics = observer((props: any) => {
    const {projectStore, c3Store} = useStore()
    const [t] = useTranslation("constituency_command_center")
    const header = safe_get(toJS(c3Store.demographics_electoral), 'booth_gender.header', [])
    const rows = safe_get(toJS(c3Store.demographics_electoral), 'booth_gender.rows', [])
    const footer = safe_get(toJS(c3Store.demographics_electoral), 'booth_gender.footer', [])
    const by_age_rows = safe_get(toJS(c3Store.demographics_electoral), 'booth_age_range.rows', [])

    useEffect(() => {
        c3Store.get_electoral_demographics()
    }, [c3Store.selected_booths])
    if (header.length == 0) {
        return (
            <>
                <div className='italic font-small text-red-500'>{t("no_voters_here")}...</div>
            </>
        )
    }

    const booth_cols = [
        {
            Header: "Booth#",
            accessor: (row) => row[0],
            Footer:"Total"
        },
        {
            Header: "Male",
            accessor: (row) => row[1],
            Footer: get_total(rows, 1)
        },
        {
            Header: "Female",
            accessor: (row) => row[2],
            Footer: get_total(rows, 2)
        },
        {
            Header: "Total",
            accessor: (row) => row[3],
            Footer: get_total(rows, 3)
        }
    ]

    const age_cols = [
        {
            Header: "Booth#",
            accessor: (row) => row[0],
            Footer:"Total"
        },
        {
            Header: "18-23",
            accessor: (row) => row[1],
            Footer: get_total(by_age_rows, 1)
        },
        {
            Header: "24-35",
            accessor: (row) => row[2],
            Footer: get_total(by_age_rows, 2)
        },
        {
            Header: "36-50",
            accessor: (row) => row[3],
            Footer: get_total(by_age_rows, 3)
        },
        {
            Header: "51-65",
            accessor: (row) => row[4],
            Footer: get_total(by_age_rows, 4)
        },
        {
            Header: "Above 65",
            accessor: (row) => row[5],
            Footer: get_total(by_age_rows, 5)
        },
        {
            Header: "Total",
            accessor: (row) => row[6],
            Footer: get_total(by_age_rows, 6)
        }
    ]


    return (
        <>
            <div className='font-bold'>{t("voters")}</div>
            <section className="grid grid-cols-1 sm:grid-cols-2 gap-2 my-4">
                <div>
                    <p className="font-bold fp-text">Gender</p>
                    <CMTable data={rows} columns={booth_cols} pagesize={10} onRowClick={() => {}} hideFooter={false}/>
                </div>
                <div>
                    <p className="font-bold fp-text">Age Group</p>
                    <CMTable data={by_age_rows} columns={age_cols} pagesize={10} onRowClick={() => {}} hideFooter={false}/>
                </div>
            </section>

            {/*<div className="-my-2 -mx-4 overflow-x-hidden sm:-mx-6 lg:-mx-8">*/}
            {/*    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">*/}
            {/*        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">*/}
            {/*            <table className="min-w-full divide-y divide-gray-300">*/}
            {/*                <thead className="bg-gray-50">*/}
            {/*                <tr>*/}
            {/*                    <th scope="col"*/}
            {/*                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">*/}
            {/*                        {header[0]}*/}
            {/*                    </th>*/}

            {/*                    {header.slice(1).map(x => {*/}
            {/*                        return (*/}
            {/*                            <th scope="col"*/}
            {/*                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 text-right">*/}
            {/*                                {x}*/}
            {/*                            </th>*/}
            {/*                        )*/}
            {/*                    })}*/}
            {/*                </tr>*/}
            {/*                </thead>*/}
            {/*                <tbody className="divide-y divide-gray-200 bg-white">*/}
            {/*                {rows.map((row, index) => {*/}
            {/*                    const booth = row[0];*/}
            {/*                    const rest = row.slice(1);*/}

            {/*                    return (*/}
            {/*                        <tr key={index}>*/}
            {/*                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">*/}
            {/*                                {t("booth")} #{booth}*/}
            {/*                            </td>*/}

            {/*                            {rest.map(col => {*/}
            {/*                                return (*/}
            {/*                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-right">*/}
            {/*                                        {col}*/}
            {/*                                    </td>*/}
            {/*                                )*/}
            {/*                            })}*/}
            {/*                        </tr>*/}
            {/*                    )*/}
            {/*                })}*/}
            {/*                </tbody>*/}
            {/*                <tfoot>*/}
            {/*                <tr>*/}
            {/*                    <th scope="col"*/}
            {/*                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">*/}
            {/*                        {footer[0]}*/}
            {/*                    </th>*/}

            {/*                    {footer.slice(1).map(x => {*/}
            {/*                        return (*/}
            {/*                            <th scope="col"*/}
            {/*                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 text-right">*/}
            {/*                                {x}*/}
            {/*                            </th>*/}
            {/*                        )*/}
            {/*                    })}*/}
            {/*                </tr>*/}
            {/*                </tfoot>*/}
            {/*            </table>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </>
    )
})


export const C3Demographics = observer((props: any) => {
    const [t] = useTranslation("constituency_command_center");

    return <>
        <div className='uppercase'>{t("demographics")}</div>
        <ElectoralDemographics/>
    </>
})