import {observer} from "mobx-react-lite"
import React, {useEffect, useState} from "react";
import {useStore} from "../../helpers";
import {useParams} from "react-router-dom";
import {AddEditGrievance} from "./add-edit-grievance";
import {GrievanceTable} from "./grievance-table";
import {useTranslation} from "react-i18next";
import {
    FilterButton,
    LoadingState,
    ProjectHeader,
    SideOverlay
} from "../../components";
import {Button, Pagination, Stack} from "@mui/material";
import {GrievanceFilter} from "./grievanceFilter";
import {useGetFullProject, useIsMobile} from "../../custom-hooks";
import {RNSearchBar} from "../influential-leaders";
import {useQuery} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {GrievanceCrumbs} from "./grievance-crumbs";


export const GrievanceManagement = observer((props: any) => {

    const rootStore = useStore();
    const {grievanceStore, flexiStore} = rootStore;
    const {fsd_search_page_size} = flexiStore;
    const {isLoading, isShowGrievanceForm, FilterStatus, filters} = grievanceStore
    const isMobile = useIsMobile();
    const [query, setQuery] = useState("");
    const {projectid} = useParams();
    const {t} = useTranslation("grievance_management");
    const [total, setTotal] = useState(0);
    const { isLoading: fullProjectLoading } = useGetFullProject()

    const [pageNumber, setPageNumber] = useState(1);
    const totalPages = Math.ceil(total / fsd_search_page_size);

    const handlePerPageChange = (event) => {
        flexiStore.update_fsd_search_page_size(parseInt(event.target.value));
    };

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
    };

    const close_Filter_modal = () => {
        grievanceStore.setFilterStatus(false)
    }

    const addEditGrievance = async () => {
        grievanceStore.update_grievance_store_property("grievance_edit_mode", false)
        grievanceStore.update_grievance_store_property("isLoading", true)
        const res = await flexiStore.clone_fsd(420);
        grievanceStore.update_grievance_store_property("isLoading", false)
        if (res.status === 200) {
            grievanceStore.update_grievance_store_property("clonedGrievance", res.data);
            flexiStore.set_current_fsd(res.data);
            grievanceStore.update_grievance_store_property("isShowGrievanceForm", true)
        }
    }

    const getGrievanceList = async () => {
        await flexiStore.set_fsd_search_query(query);
        const res = await flexiStore.fetch_fsdlist("grievance", parseInt(projectid), filters);
        if (!res.ok) {
           throw new Error ("Something went wrong")
        } else return res
    }

    const handleSearchBarChange = (value) => {
        setQuery(value)
    }

    const handleCloseModal = () => {
        grievanceStore.update_grievance_store_property("isShowGrievanceForm", false)
        grievanceStore.update_grievance_store_property("isViewGrievance", false)
    }

    useEffect(()=> {
        //TODO: Reset filters when this component unmounts
        return () => {
            grievanceStore.resetFilters()
        }
    }, [])

    const {isLoading: loading} = useQuery({
        queryKey: ["grievances", projectid, filters, query, pageNumber, fsd_search_page_size],
        queryFn: getGrievanceList,
        onSuccess: data => {
            grievanceStore.update_grievance_store_property("grievanceList", data["data"]["results"])
            setTotal(data["data"]["total"])
        },
        onError: e => toast.error(e["message"])
    })

    return <>
        <div>
            {(isLoading || loading || fullProjectLoading) && <LoadingState/>}
            {!isMobile ?
                <>
                    <GrievanceCrumbs/>
                    <ProjectHeader/>
                </> : null}
            <div className="p-4 overflow-hidden">
                <div className="flex align-center justify-between gap-x-4">
                    <div className='flex flex-row justify-between items-center mb-4 flex-1'>
                        <h2 className='font-bold text-3xl text-indigo-500 pr-14'>{t("grievance_management")}</h2>
                        <div className={"flex items-start justify-between gap-x-4"}>
                            <RNSearchBar onSearch={handleSearchBarChange}/>
                            <Button
                                variant={"contained"}
                                startIcon={<i className="fas fa-plus-circle"/>}
                                onClick={addEditGrievance}
                            >
                                {t("new_grievance")}
                            </Button>
                        </div>
                    </div>
                    <FilterButton
                        filterOnClickHandler={() => grievanceStore.setFilterStatus(true)}
                    />
                </div>

                <Stack spacing={2}>
                    {totalPages ? <Pagination boundaryCount={5} count={totalPages} page={pageNumber} onChange={handlePageChange}/> : null}
                </Stack>

                <GrievanceTable/>

                <SideOverlay
                    children={<AddEditGrievance getGrievanceList={getGrievanceList}/>}
                    onClose={handleCloseModal}
                    show={isShowGrievanceForm}
                />

                <SideOverlay
                    children={<GrievanceFilter/>}
                    onClose={close_Filter_modal}
                    show={FilterStatus}
                    title={t("Grievance Filters")}
                />

            </div>
        </div>

    </>
})