import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../helpers";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import { safe_get } from "../report-visuals/report-utils";
import {useQuery} from "@tanstack/react-query";
import {LoadingState} from "../components";

export const theme = "#009EA6";

export const DashboardPage = observer((props: any) => {
    const rootStore = useStore();
    const navigate = useNavigate();

    const {projectid} = useParams()

    const fetchData = async () => {
        return await rootStore.api.post('project/myprojects', {})
    }
    console.log("DashboardPage")

    const {data, status, isError, isLoading} = useQuery({
        queryKey: ['projects'],
        queryFn: fetchData,
        onSuccess: data => {
            if(!projectid || projectid === "undefined") {
                const default_project_id = safe_get(data.data[0], "id", "1")
                navigate(`project/${default_project_id}`);
            }
        }
    })

    if (isLoading) return <LoadingState />

    return (
        <>
            <Outlet />
        </>
    );

});
