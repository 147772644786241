import React from "react";
import {Route, Routes} from "react-router-dom";
import {ReportEditPage, ReportsLanding, ReportViewPage} from "../pages/reports";

export const ReportRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<ReportsLanding/>}/>
            <Route path="/view/:reportid" element={<ReportViewPage/>}/>
            <Route path="/share/:token" element={<ReportViewPage/>}/>
            <Route path="/edit/:reportid" element={<ReportEditPage/>}/>
        </Routes>
    )
}