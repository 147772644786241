import { observer } from "mobx-react-lite";
import { Outlet, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useStore } from "../../helpers";
import C3Items from "./c3-items";
import { useTranslation } from "react-i18next";
import 'react-dropdown-tree-select/dist/styles.css';
import {useIsMobile} from "../../custom-hooks";
import moment from "moment";
import {C3BoothFilter, C3ConcernedPerson, DateFilter} from "./filters";
import {BreadCrumbs, FilterButton, FormButtons, ProjectHeader, SideOverlay} from "../../components";
import {Button} from "@mui/material";


type c3FilterType = {
    booths: string[],
    start_date: string,
    end_date: string,
    office_persons: string[]
}

const defaultC3Filters: c3FilterType = {
    booths: [],
    start_date: '',
    end_date: '',
    office_persons: []
};

export const C3Home = observer((props: any) => {
    const { projectStore, c3Store } = useStore()
    const isMobile = useIsMobile();
    const [t] = useTranslation("constituency_command_center");
    const hierarchy = projectStore.getProjectProperty('hierarchy');
    const { projectid } = useParams();
    const { pathname } = useLocation();
    const [showFilterPane, setShowFilterPane] = useState(false);
    const [loading, setLoading] = useState(false)

    const [c3Filters, setC3Filters] = useState<c3FilterType>(defaultC3Filters);

    const showDateSlider = pathname.includes("beneficiaries") || pathname.includes("grievance");

    const crumbs = [
        { name: "DashBoard", href: `/project/${projectid}`, current: true },
        { name: 'Constituency Command Center', href: '/project/' + projectid + '/c3', current: true },
    ];

    useEffect(() => {
        c3Store.setHierarchyData(hierarchy)
    }, []);

    const onBoothSelection = (booths: []) => {
        setC3Filters({ ...c3Filters, booths: booths })
    }

    const onDateSelection = (start_date, end_date = moment().format("YYYY-MM-DD")) => {
        setC3Filters({ ...c3Filters, start_date: start_date, end_date: end_date })
    }

    const filter_save_handler = () => {
        setLoading(true)
        c3Store.update_selected_booths(c3Filters.booths);
        c3Store.update_date_filters(c3Filters.start_date, c3Filters.end_date);
        setShowFilterPane(false)
        setLoading(false)
    }

    const filter_cancel_handler = () => {
        setShowFilterPane(false)
    }

    return (
        <div className="relative">
            {!isMobile ?
                <>
                    <BreadCrumbs crumbs={crumbs} />
                    <ProjectHeader />
                </> : null}

            <FilterButton
                sx={{position: "absolute", top: 16, right: 64}}
                filterOnClickHandler={e => setShowFilterPane(true)}
            />

            <div className='px-4 flex flex-row'>
                {c3Store.selected_booths.length > 0 && (
                    <>
                        <p className="font-bold fp-text text-md mr-r p-2">Selected Booths:</p>
                        <div className="flex flex-row flex-wrap px-2">
                            {c3Store.selected_booths.map((booth) => (
                                <p className="p-2 font-bold">Booth# {booth},</p>
                            ))}
                        </div>
                    </>
                )}
            </div>


            <div className="bg-gray-100">
                <div className="flex flex-col sm:flex-row mx-2 sm:mx-auto ">
                    <div className="w-full sm:w-1/5">
                        <C3Items />
                    </div>
                    <div className="w-full sm:w-4/5 bg-white p-4 overflow-x-hidden h-[89vh]">
                        <Outlet />
                    </div>
                </div>
            </div>

            <SideOverlay
                title="Select Filters"
                onClose={e => setShowFilterPane(false)}
                show={showFilterPane}
            >
                <>
                    <div className='px-4 bg-gray-100 bg-white rounded-lg flex flex-col flex-1'>
                        <div className='custom-dropdown py-2 flex flex-col flex-1'>
                            <p className="text-blue-600 font-bold text-xl px-2 py-4">Booth Filters</p>
                            <C3BoothFilter
                                onBoothSelection={onBoothSelection}
                            />
                        </div>
                        <div className='custom-dropdown py-2 flex flex-col flex-1'>
                            <p className="text-blue-600 font-bold text-xl px-2 py-4">Concerned Office Person</p>
                            <C3ConcernedPerson/>
                        </div>

                        {showDateSlider && <div className='py-2 flex flex-col flex-1'>
                            <div className={"flexBoxRow"}>
                                <DateFilter onDateChange={onDateSelection} startDate={c3Filters.start_date}
                                            endDate={c3Filters.end_date}/>
                                <Button
                                    variant={"contained"}
                                    startIcon={<i className={"fas fa-exchange-alt"}/>}
                                    size={"small"}
                                    color={"warning"}
                                    className="btn btn-primary btn-sm"
                                    onClick={() => c3Store.update_date_filters("", "")}
                                >Reset Date
                                </Button>
                            </div>
                        </div>}
                    </div>
                    <FormButtons
                        onSave={filter_save_handler}
                        onCancel={filter_cancel_handler}
                    />
                </>
            </SideOverlay>
        </div>

    )
}
)
