import React from "react";
import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from '@headlessui/react'
import {XCircleIcon} from "@heroicons/react/24/solid";

type SideOverlayProps = {
    children: React.ReactNode;
    onClose: Function;
    show: boolean;
    title?: string;
};

export const SideOverlay = (props: SideOverlayProps) => {
    const {children, onClose, show, title = ""} = props;
    return (
        <>
            <Dialog
                open={show}
                onClose={() => {}}
                className="relative z-[999]"
            >
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
                />
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-lg sm:max-w-full pl-10">
                            <DialogPanel
                                transition
                                className="pointer-events-auto w-screen max-w-xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                            >
                                <div
                                    className="flex h-full flex-col overflow-y-scroll bg-gray-100 py-6 shadow-xl rounded-l-lg">
                                    <div className="px-4 sm:px-6">
                                        <div className="flex items-start justify-between">
                                            <DialogTitle
                                                className="text-2xl font-bold text-purple-600 uppercase">{title}</DialogTitle>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    //@ts-ignore
                                                    onClick={onClose}
                                                    className="rounded-xl text-xl text-gray-400 hover:text-gray-500 focus:outline-none hover:bg-white"
                                                >
                                                    <span className="sr-only">Close panel</span>
                                                    <XCircleIcon className="h-8 w-8" aria-hidden="true"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">{children}</div>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};
