import React, {useState} from "react"
import {isEmpty} from "lodash";
import {DataNotAvailable} from "./mh-2024-fieldview-main-page";
import {Tables} from "./mh-tables";
import {DonutChart3d} from "../../../components";

export const MHDemographics = ({data}) => {

    const elections = Object.keys(data)
    const [activeSection, setActiveSectio] = useState(elections[0])
    if(isEmpty(data)) return <DataNotAvailable />

    return (
        <>
            <div className={"flex flex-row flex-wrap w-full py-4"}>
                {
                    Object.keys(data).map(key => {
                        const bg = (activeSection === key) ? 'bg-indigo-500 text-white font-bold' : 'bg-gray-200 border border-black'
                        return (
                            <div
                                className={`mt-2 py-2 px-4 text-sm ${bg} w-fit cursor-pointer mr-4 rounded-2xl capitalize`}
                                onClick={() => setActiveSectio(key)}
                            >
                                {key.replace(/_/g, " ")}
                            </div>
                        )
                    })
                }
            </div>
            {activeSection !== '' ? (<Tables table={data[activeSection]}/>) : null}
            {activeSection.includes("swot") ? <DonutChart3d data={
                [
                    {
                        "label": "Created",
                        "value": 7,
                        "color": "#6B7280"
                    },
                    {
                        "label": "Approved",
                        "value": 3,
                        "color": "#22C55E"
                    },
                    {
                        "label": "Pending",
                        "value": 0,
                        "color": "#EAB308"
                    },
                    {
                        "label": "Rejected",
                        "value": 0,
                        "color": "#EF4444"
                    }
                ]
            } _label="label" value="value" title={"Summary"}/> : null}

        </>
    )
}