import React, {StrictMode} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { RootStore } from "./stores/root";
import { StoreProvider } from "./helpers";
import "./i18n";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorBoundaryFallBack } from "./pages";

const initialRoot = new RootStore();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <>
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallBack}
      onReset={(details) => {
      }}
    >
      <StoreProvider value={initialRoot}>
        <BrowserRouter>
            <StrictMode>
                <App />
            </StrictMode>
        </BrowserRouter>
      </StoreProvider>
    </ErrorBoundary>
  </>
);

// reportWebVitals is a function that allows you to measure the performance of your app.
// You can log the results to the console or send them to an analytics endpoint.
reportWebVitals();
