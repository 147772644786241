import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { safe_get } from "../../report-visuals";
import { toast } from "react-toastify";
import {useTranslation} from "react-i18next";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FilterHeader} from "../voter-list";
import {error_toast, useStore} from "../../helpers";


export const Booths = observer((props: any) => {
 
  const { filter, booths } = props;
  const { projectStore, userStore, beneficiaryStore } = useStore();
  const { activeUser } = userStore;
  const { SearchBooth } = beneficiaryStore;
  const [t] = useTranslation("user_management");
  const [hierarchy, setHierarchy] = useState([]);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [assignAllBooths, setAssignAllBooths] = useState(true);
  const hasPermission = activeUser.role === "root" || activeUser.role === "client"

  
  useEffect(() => {
    setHierarchy(projectStore.getProjectProperty("hierarchy", []));
    const selected_booths = safe_get(JSON.parse(localStorage.getItem("userpartsandperms")), "parts", []);
    setChecked(filter ? SearchBooth : selected_booths)
  }, []);

  useEffect(() => {
    if (hasPermission) {
      const booths = [];
      hierarchy.map((x) => {
        x["children"].map((y) => {
          booths.push(y["id"])
        });
      });
      setChecked(booths);
      if (checked.length !== 0) {
        update_assigned_booths_to_server();
      }
    }
  }, [hierarchy]);

  const update_assigned_booths_to_server = async () => {
    const res = await userStore.updateUserHierarchy({
      projectid: projectStore.activeProject["id"],
      userid: userStore.activeUser["id"],
      hierarchy: checked
    });

    if(res.status === 200){
      toast.success("Booths assigned successfully.")
    } else error_toast();
  };

  return (
    <div className="flex flex-col justify-between bg-white px-4 py-2 my-4 rounded-lg">
      {filter &&  <FilterHeader title={"BOOTH FILTER"} content={"Filter by Booth number"} />}
      {checked.length === 0 && !filter &&
        <div className="text-red-300 text-md text-bold p-2">
          {t("assign_booths")}
        </div>
      }
      {/*{hasPermission ?*/}
      {/*  <div className="flex flex-row items-center">*/}
      {/*    <label className="label cursor-pointer">*/}
      {/*      <span className="label-text text-lg text-blue-500 font-bold">Assign All</span>*/}
      {/*      <input*/}
      {/*          type="checkbox"*/}
      {/*          checked={assignAllBooths}*/}
      {/*          onChange={() => setAssignAllBooths(!assignAllBooths)}*/}
      {/*          className="checkbox checkbox-primary checkbox-sm app-checkbox mx-4"*/}
      {/*      />*/}
      {/*    </label>*/}
      {/*  </div>*/}
      {/*: null}*/}
      <div className="flex flex-col">
        <CheckboxTree
          nodes={hierarchy}
          checked={checked}
          expanded={expanded}
          checkModel={"leaf"}
          onCheck={(check, targetnode) => {
            setChecked(check);
            booths(check, "booths");
          }}
          onExpand={(expand, targetnode) => {
            setExpanded(expand);
          }}

          icons={{
            check: (
                <FontAwesomeIcon
                    className="rct-icon rct-icon-check"
                    icon="check-square"
                />
            ),
            uncheck: (
                <FontAwesomeIcon
                    className="rct-icon rct-icon-uncheck"
                    icon={["fas", "square"]}
                />
            ),
            halfCheck: (
                <FontAwesomeIcon
                    className="rct-icon rct-icon-half-check"
                    icon="check-square"
                />
            ),
            expandClose: (
                <FontAwesomeIcon
                    className="rct-icon rct-icon-expand-close"
                    icon="chevron-right"
                />
            ),
            expandOpen: (
                <FontAwesomeIcon
                    className="rct-icon rct-icon-expand-open"
                    icon="chevron-down"
                />
            ),
            expandAll: (
                <FontAwesomeIcon
                    className="rct-icon rct-icon-expand-all"
                    icon="plus-square"
                />
            ),
            collapseAll: (
                <FontAwesomeIcon
                    className="rct-icon rct-icon-collapse-all"
                    icon="minus-square"
                />
            ),
            parentClose: (
                <FontAwesomeIcon
                    className="rct-icon rct-icon-parent-close"
                    icon="folder"
                />
            ),
            parentOpen: (
                <FontAwesomeIcon
                    className="rct-icon rct-icon-parent-open"
                    icon="folder-open"
                />
            ),
            leaf: (
                <FontAwesomeIcon
                    className="rct-icon rct-icon-leaf-close"
                    icon="file"
                />
            ),
          }}
        />
        {!filter && (
          <button
            className="px-4 flex py-2 rounded-md bg-indigo-700 text-white self-end"
            onClick={async() => {
              await update_assigned_booths_to_server();
              userStore.setUserDetailsMode(false);
            }}
          >
            {t("assign")}
          </button>
        )}
      </div>
    </div>
  );
});
